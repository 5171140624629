<template>
  <div>
    <div class="d-flex justify-content-center align-center">
      <div class="add-btn cursor-pointer" @click="openModal">
        <feather-icon icon="PlusIcon" /> Mahsulot qo'shish
      </div>
    </div>
    <div class="a4-page">
      <div class="custom-row">
        <div v-for="(product, index) in products" :key="index" class="custom-col">
          <div class="price-tag bg-white position-relative">
            <div class="d-flex justify-content-end">
              <div class="copyPrintBtn" @click="$emit('copy-product', product)">
                <feather-icon icon="CopyIcon" />
              </div>
              <div class="deletePrintBtn" @click="$emit('delete-product', index)">
                <feather-icon icon="Trash2Icon" />
              </div>
            </div>
            <div class="logo-text">
              SALOMHAYOT
            </div>
            <div class="price">
              <span>
                {{ product.price | formatPrice }}
              </span>
            </div>
            <div class="parent_name">
              {{ getCategoryParentName(product.category.id) }}
            </div>
            <div class="name">
              {{ product.name_uz }}
            </div>
            <div class="first_payment">
              <p><b>Boshlang'ich to'lov: </b> {{ product.first_payment | formatPrice }} so'm</p>
            </div>
            <div class="py-30">
              <div class="monthly-price">
                <span class="month">12 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_12 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
              <div class="monthly-price">
                <span class="month">18 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_18 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
              <div class="monthly-price">
                <span class="month">24 oy</span>
                <div class="mprice">
                  {{ product.price_monthly_24 | formatPrice }}
                  <span>so'mdan</span>
                </div>
              </div>
            </div>
            <div class="code">
              <span>
                {{ product.barcode ? Number(product.barcode) : '0' }}
              </span>
            </div>
          </div>
          <div v-if="(index + 1) % size == 0" class="brak-page" />
        </div>
      </div>
    </div>
    <save v-if="visible" ref="saveForm" @onSuccess="setProduct" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import save from './save.vue'

export default {
  name: 'PriceTag',
  components: { save },
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
    size: {
      type: Number,
      default() {
        return 4
      },
    },
    isPrintingProcess: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      visible: true,
      categoryCache: {}, // Cache for category data
      pendingRequests: {}, // Track pending API requests
      productForm: {
        p0_category_id: null,
        p1_category_id: null,
        category_id: null,
      },
    }
  },
  watch: {
    // Watch for products changes to load category data for new products
    products: {
      handler(newProducts) {
        this.loadCategoryDataForProducts(newProducts)
      },
      deep: true,
    },
  },
  created() {
    // Prefetch category data for all products
    this.prefetchCategoryData()
  },
  methods: {
    ...mapActions({ getProductInfo: 'productCategory/show' }),

    prefetchCategoryData() {
      this.loadCategoryDataForProducts(this.products)
    },

    loadCategoryDataForProducts(products) {
      const categoryIds = [...new Set(products
        .filter(p => p.category && p.category.id)
        .map(p => p.category.id))]

      categoryIds.forEach(id => {
        this.fetchCategoryData(id)
      })
    },

    fetchCategoryData(id) {
      // Skip if already in cache or a request is pending
      if (this.categoryCache[id] || this.pendingRequests[id]) return Promise.resolve()

      // Mark request as pending
      this.$set(this.pendingRequests, id, true)

      return this.getProductInfo({
        id,
        relations: 'photos',
      }).then(res => {
        // Cache the response data
        this.$set(this.categoryCache, id, {
          parent0_name_uz: res.data.parent0_name_uz,
          parent1_name_uz: res.data.parent1_name_uz,
          parent2_name_uz: res.data.parent2_name_uz,
        })
        // Clear pending flag
        this.$set(this.pendingRequests, id, false)
        return res
      }).catch(err => {
        console.error('Failed to fetch category data:', err)
        this.$set(this.pendingRequests, id, false)
      })
    },

    getCategoryParentName(categoryId) {
      return this.categoryCache[categoryId]
        ? this.categoryCache[categoryId].parent1_name_uz
        : this.fetchAndGetParentName(categoryId)
    },

    fetchAndGetParentName(categoryId) {
      // Fetch if not already pending
      if (!this.pendingRequests[categoryId]) {
        this.fetchCategoryData(categoryId)
      }
      return '...' // Show placeholder while loading
    },

    openModal() {
      this.visible = true
      this.$nextTick(() => {
        if (this.$refs.saveForm) {
          this.$refs.saveForm.visible = true
        }
      })
    },

    setProduct(val) {
      if (this.$refs.saveForm) {
        this.$refs.saveForm.visible = false
      }
      this.visible = false

      // If the new product has a category, fetch its data
      if (val && val.category && val.category.id) {
        this.fetchCategoryData(val.category.id)
      }

      this.$emit('setProduct', val)
    },

    // These methods now emit events instead of directly modifying props
    copyProduct(index) {
      this.$emit('copy-product', this.products[index])
    },

    deleteProduct(index) {
      this.$emit('delete-product', index)
    },
  },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/price-tag-print.scss";
</style>
