<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="mt-2">
        <b-overlay :show="!product_categories.data">
          <b-form-group
            :label="$t('Mahsulot')"
            label-for="category"
          >
            <validation-provider
              #default="{ errors }"
              name="category"
              rules="required"
            >
              <v-select
                id="category"
                ref="vSelect"
                v-model="category"
                :state="errors.length > 3 ? false : null"
                :options="product_categories.data"
                label="name_uz"
                :selectable="option => Number(option.price_dollar)"
                @input="changeCategory"
              >
                <span slot="no-options"> Kechirasiz, tovar topilmadi </span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-overlay>
        <b-overlay>
          <b-form-group
            :label="$t('Shtrix-kod bo`yicha izlash')"
            label-for="barcode"
          >
            <b-form-input
              id="barcode"
              v-model="barcodeSearch"
              type="number"
              name="letter"
              @input="searchByBarcode"
            />
          </b-form-group>
          <div
            v-for="(item, index) in foundByBarcodeProducts"
            :key="index"
            class="label-tag"
            @click="setProductByBarcode(item)"
          >
            {{ item.category.name_uz }}
          </div>
        </b-overlay>
        <hr>
        <b-form-group
          v-if="category"
          :label="$t('Mahsulot nomi')"
          label-for="letter"
        >
          <b-form-input
            id="letter"
            v-model="category.name_uz"
            name="letter"
          />
        </b-form-group>
        <b-form-group
          v-if="category"
          :label="$t('Barkodni tanlang')"
          label-for="barcode"
        >
          <v-select
            id="barcode"
            v-model="barcodeProduct"
            :options="barcodesProduct"
            label="barcode"
            @input="changeBarcode"
          />
        </b-form-group>
        <hr>
        <b-form-group
          label="Boshlang'ich to'lov"
          label-for="in_advance_payment_amount"
        >
          <b-overlay :show="form_loading">
            <cleave
              id="in_advance_payment_amount"
              v-model="formCalc.in_advance_payment_amount"
              class="form-control"
              :options="cleaveOption"
              placeholder="0"
              @input="getCreditAmount"
            />
          </b-overlay>
        </b-form-group>
        <hr>
        <div v-if="category">
          <p>
            <b>Narxi:</b> {{ category.price | formatPrice }}
            <span
              v-if="formCalc.credit_amount"
            >(Qoldiq: {{ formCalc.credit_amount | formatPrice }})</span>
          </p>
          <p><b>12 oy:</b> {{ category.price_monthly_12 | formatPrice }}</p>
          <p><b>18 oy:</b> {{ category.price_monthly_18 | formatPrice }}</p>
          <p><b>24 oy:</b> {{ category.price_monthly_24 | formatPrice }}</p>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
  },
  props: {},
  data() {
    return {
      form: {
        id: null,
        amount: '',
        name_ru: '',
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      formCalc: {
        products: [],
        in_advance_payment_amount: null,
        start_date: null,
        end_date: null,
        credit_payment_day: '15',
        credit_amount: null,
        credit_months: null,
      },
      form_loading: false,
      filterProduct: {
        level: 3,
        per_page: 9999,
        belongs_to_hired_worker: null,
        name_uz: '',
      },
      monthes: [12, 18, 24],
      barcodesProduct: [],
      barcodeProduct: '',
      category: null,
      title: '',
      visible: false,
      barcodeSearch: null,
      foundByBarcodeProducts: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      product_categories: 'productCategory/GET_ITEMS',
    }),
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Добавить значение')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.fetchProducts()
    this.formCalc.start_date = this.today('ymd')
    this.getCreditAmount = this.debounce(this.getAmount, 600)
  },
  methods: {
    debounce(fn, wait) {
      let timer
      return function (...args) {
        if (timer) {
          clearTimeout(timer) // clear any pre-existing timer
        }
        const context = this // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args) // call the function if time expires
        }, wait)
      }
    },
    getCreditAmount() {},
    getAmount() {
      this.form_loading = true
      this.changeCategory(this.category)
    },
    today(type = 'ymd') {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      if (type == 'ymd') {
        today = `${yyyy}-${mm}-${dd}`
      } else {
        today = `${dd}.${mm}.${yyyy}`
      }
      return today
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.$emit('onSuccess', this.category)
      } else {
        showToast('warning', this.$t('Mahsulotni tanlang'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    changeCategory(val) {
      this.getProductsForPriceTag({
        category_id: val.id,
        in_advance_payment_amount: this.formCalc.in_advance_payment_amount,
      })
        .then(res => {
          this.barcodesProduct = res.data
          if (res.data && res.data.length) {
            this.barcodeProduct = res.data[0]
            this.changeBarcode(res.data[0])
          }
        })
        .finally(() => {
          this.form_loading = false
        })
    },
    searchByBarcode(val) {
      if (val && String(val).length > 3) {
        const stringWithZeros = String(val).padStart(12, '0')
        this.getProductsForPriceTag({ barcode: stringWithZeros }).then(
          res => {
            this.foundByBarcodeProducts = res.data
          },
        )
      }
    },
    setProductByBarcode(product) {
      this.barcodesProduct = [product]
      this.barcodeProduct = product
      this.changeBarcode(product)
      this.formCalc.in_advance_payment_amount = Number(product.category.in_advance_payment_amount_dollar) * this.dollarRate
    },
    changeBarcode(val) {
      const product = val
      product.quantity = 1
      this.formCalc.products = [product]
      this.category = val
      this.category.name_uz = val.name_uz ? val.name_uz : val.category.name_uz
      this.category.id = val.category_id ? val.category_id : val.id
      this.category.first_payment = this.formCalc.in_advance_payment_amount
    },

    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    fetchProducts() {
      this.getCategories(this.filterProduct)
    },
    ...mapActions({
      calculateCreditAmount: 'resource/calculateCreditAmount',
      calculateCreditSchedule: 'resource/calculateCreditSchedule',
      getCategories: 'productCategory/index',
      getProductsForPriceTag: 'productCategory/productsForPriceTag',
      storeItem: 'resource/storeDollarRates',
      updateItem: 'resource/updateUnitOfMeasure',
    }),
  },
}
</script>

<style scoped></style>
